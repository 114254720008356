<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="Departments"
          :breadcrumb="[
             {
                label: 'Dashboard',
            },
            {
              label: 'Masters',
            },
            {
              label: 'Departments',
            }
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
              style="height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <div class="col-8">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder">
                        Staff Departments</b
                      >
                    </h4>
                  </div>
                  <div class="col-4 text-end">
                    <button
                    @click="formNull()"
                      type="button"
                      class="btn btn-sm text-white"
                      style="background-color: #f21000"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <strong>Add New</strong>
                    </button>
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top:5px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>+</th>

                          <th>Name</th>
                          <th>Remark</th>
                          <th class="text-center">Status</th>
                          <th class="text-end">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(role, index) in allusers"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>

                            <td>{{ role.name }}</td>
                            <td>{{ role.remark }}</td>

                            <td class="text-center">
                              <p
                                v-if="role.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  title="Edit"
                                  @click="departmentEdit(role)"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>

                                <!-- <button
                                  type="button"
                                  class="btn btns btn-sm btn-danger"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="trash" />
                                </button> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- department model  -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Staff Department</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="edit == 'false' ? create() : update()">
            <div class="form-group" style="padding: 5px 5px 5px 5px">
              <label>Name <samll style="color:#f21000">*</samll></label>
              <input
              required
                type="text"
                v-model="form.name"
                class="form-control"
                placeholder="Name"
              />
              <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.name">{{
                          form.errors.error.name[0]
                        }}</span></span
                      ></small
                    >
            </div>

            <div class="form-group" style="padding: 5px 5px 5px 5px">
              <label>Status <samll style="color:#f21000">*</samll></label>
              <select
              required
                v-model="form.status"
                class="form-select"
                aria-label="Default select example"
              >
                <option value="true">Active</option>
                <option value="false">InActive</option>
              </select>
              <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.status">{{
                          form.errors.error.status[0]
                        }}</span></span
                      ></small
                    >
            </div>

            <div class="form-group" style="padding: 5px 5px 5px 5px">
              <label>Remark</label>
              <textarea v-model="form.remark" class="form-control" rows="3"></textarea>
              <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.remark">{{
                          form.errors.error.remark[0]
                        }}</span></span
                      ></small
                    >
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "User Roles",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      allusers: [],
      edit: "false",
      form: {
        name: "",
        remark: "",
        status: "",
        errors:{}
      },
    };
  },
  methods: {
    formNull(){
      this.edit = "false";
      this.form = {}
      this.form.errors = {}
    },
    departmentEdit(data) {
      this.form = data;
      this.edit = "true";
    },
    create() {
      this.loading = true;
      this.$axios
        .post("superadmin/department", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.userRolse();
          this.form = {};
          $("#exampleModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            this.form.errors = error.response.data;
          });
    },
    update() {
      this.loading = true;
      this.$axios
        .put(`superadmin/department/${this.form.id}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.userRolse();
          this.edit = "false";
          this.form = {};
          $("#exampleModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            this.form.errors = error.response.data;
          });
    },
    userRolse() {
      this.loading = true;
      this.$axios
        .get("superadmin/department", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.allusers = res.data.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error);
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.departments
    console.log(this.$store.state.superAdminPageTitles.departments)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.userRolse();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
